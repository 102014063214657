.type3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    position: relative;
    width: 47%;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    width: calc(50% - 10px);
    margin-bottom: 20px;
    background-color: #fff;

    .item_left {
      width: 74px;
      height: 74px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 14px;
      margin-right: 16px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
        border: 1px solid #dadada;
      }
    }

    .item_center {
      flex: 1;

      .name {
        font-weight: 600;
        color: #222;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 10px;
      }

      .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 12px;
        cursor: pointer;
        font-weight: 600;
        color: #222;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-transform: capitalize;
      }

      .time {
        font-size: 12px;
        font-weight: 400;
        color: #999;
        line-height: 21px;
        cursor: pointer;
      }
    }

    .item_right {
      //width: 30%;
      margin-left: 10px;
    }

    .item_code {
      display: none;
      position: absolute;
      height: 24px;
      border-radius: 0 12px 0 12px;
      right: 0;
      top: 0;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      line-height: 24px;
      text-align: center;
      padding: 0 6px;
    }
  }
}

@media screen and (max-width: 900px) {
  .type3 {
    .item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 720px) {
  .type3 {
    .item {
      .item_center {
        .title {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }

      .item_right {
        display: none;
      }

      .item_code {
        display: block;
      }
    }
  }
}
